import { QUICK_FILTERS_KEY } from "modules/common/constants/QuickFilters";
import { getPersistedFilterKey } from "modules/common/hooks/usePersistedFilters";

import type { DefaultFilterConfiguration } from "modules/common/hooks/useGetUrlFilters";

import type { AccountFilters } from "../models/Filters";

export const FILTERS_KEYS = {
  QUICK_FILTERS: QUICK_FILTERS_KEY,
  COUNTRIES: "country",
  CURRENCIES: "currency",
} as const satisfies Record<string, keyof AccountFilters>;

export const DEFAULT_FILTERS = {
  [FILTERS_KEYS.QUICK_FILTERS]: [],
  [FILTERS_KEYS.COUNTRIES]: [],
  [FILTERS_KEYS.CURRENCIES]: [],
} as const satisfies AccountFilters;

export const DEFAULT_FILTERS_CONFIGURATION = {
  [FILTERS_KEYS.QUICK_FILTERS]: {
    array: true,
    defaultValue: [],
  },
  [FILTERS_KEYS.COUNTRIES]: {
    array: true,
    defaultValue: [],
  },
  [FILTERS_KEYS.CURRENCIES]: {
    array: true,
    defaultValue: [],
  },
} as const satisfies DefaultFilterConfiguration<AccountFilters>;

export const FILTERS_PERSISTED_KEY = getPersistedFilterKey("accounts");
