import { getPersistedFilterKey } from "modules/common/hooks/usePersistedFilters";

import type { ClientFilters } from "../models/Filters";

export const DEFAULT_FILTERS: ClientFilters = {
  quickFilters: [],
};

export const DEFAULT_FILTERS_CONFIGURATION = {
  quickFilters: {
    array: true,
    defaultValue: [],
  },
};

export const INITIAL_FILTERS = {
  quickFilters: [],
};

export const FILTERS_PERSISTED_KEY = getPersistedFilterKey("applications");
